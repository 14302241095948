<template>
  <div>
    <p class="font-heading text-subS text-redDamask font-extrabold">{{ $t('login.pin') }}</p>
    <span class="display-block font-heading text-h4 text-sepiaBlack dark:text-white">{{ pin }}</span>
  </div> 
</template>

<script>
export default {
  name: 'PinCode',
  data() {
    return {
      pin: '',
      baseURL: process.env.VUE_APP_AXIOS_URL,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      errorMsg: ''
    }
  },
  mounted() {
    this.getPin();
    this.$i18n.locale = this.lang;
  },
  methods: {
    getPin() {
            const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
            this.axios.get(this.baseURL + `/wp-json/wp/v2/pin`, {params: {user_id: authUser.data.user.id}}, {}).then(response => {
                const response_data = response.data;
                this.pin = response_data;
            }).catch( (error) => {
                this.errorMsg = error
            });
        },
  }
}
</script>
