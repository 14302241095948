<template>
  <div class="flex justify-between items-center h-14 p-2 mx-2.5 cursor-pointer border-b border-white border-opacity-10 bg-white bg-opacity-10 filter drop-shadow-menuItem">
    <div class="flex gap-x-2.5">
      <h6 class="font-heading text-h6 text-redDamask">{{ number }}</h6>
      <p class="font-body text-bodyL text-sepiaBlack dark:text-white">{{ text }}</p>
    </div>
    <BaseIcon name="arrowRightWhite" outline= false />
  </div>
</template>

<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'MenuItem',
  components: {
    BaseIcon
  },
  props: {
    number: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
