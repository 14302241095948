<template>
   <div>
     <Menu />
   </div>
</template>

<script>
import Menu from "@/components/mobile/Menu.vue"

export default {
  name: 'MobileMenu',
  components: {
    Menu
  }
}
</script>
