<template>
<div>
  <div class="text-center relative h-screen overflow-auto">
    <img v-bind:src="getLogo()" alt="Logo" class="w-24 mx-auto my-6">
    <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white mb-2">{{ $t('menu.message', { input: name }) }}</h4>
    <PinCode v-show="pinExist" class="mb-6 mt-2"/>
    <ul class="mx-6 rounded-lg text-left mb-28">
      <li>
        <router-link :to="{ name: 'PasswordReset' }">
          <MenuItem number=01 :text="$t('menu.settings')" class="rounded-t-lg"/>
        </router-link> 
      </li>
      <li class="my-4">
        <router-link :to="{ name: 'LanguageMobile' }">
          <MenuItem number=02 :text="$t('menu.lang')" class="rounded-t-lg"/>
        </router-link> 
      </li>
      <li class="my-4">
        <router-link :to="{ name: 'Onboarding1' }">
          <MenuItem number=03 :text="$t('menu.onboarding')" class="rounded-t-lg"/>
        </router-link>
      </li>
      <li class="my-4">
        <router-link :to="{ name: 'DDD' }">
          <MenuItem number=04 :text="$t('menu.arScan')" class="rounded-t-lg"/>
        </router-link>
      </li>
      <li class="my-4">
        <router-link :to="{ name: 'MobileQRCodeReader' }">
          <MenuItem number=05 :text="$t('menu.menuQR')" class="rounded-t-lg"/>
        </router-link>
      </li>
      <li class="my-4">
        <router-link :to="{ name: 'SelfieInstruction1' }">
          <MenuItem number=06 :text="$t('menu.selfie')" class="rounded-t-lg"/>
        </router-link>
      </li> 
      <li class="my-4">
        <router-link :to="{ name: 'TimeLineList' }">
          <MenuItem number=07 :text="$t('menu.timelines')" class="rounded-t-lg"/>
        </router-link>
      </li>
      <li class="my-4" v-on:click="logOut">
        <!-- <router-link :to="{ name: 'Login' }">-->
          <MenuItem number=08 :text="$t('login.logout')" class="rounded-t-lg"/>
        <!-- </router-link>-->
        <p :class="logOutError ? 'pt-4 mx-2.5 font-body text-bodyL dark:text-white' : 'hidden'">{{$t('login.logouterror')}}</p>
      </li>
    </ul>
    <!-- <DarkMode v-on:update_class="toggleDark" /> -->
  </div>
  <MobileNavigation :pinExist="pinExist" />
  </div>
</template>

<script>
import PinCode from "@/components/PinCode.vue";
import MobileNavigation from "@/components/mobile/Navigation.vue";
// import DarkMode from '@/components/DarkMode.vue';
import MenuItem from "@/components/mobile/MenuItem.vue"
import store from '@/store'

export default {
  name: 'Menu',
  data() {
    return {
      name: window.localStorage.name,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      logOutError: false,
      pinExist: false,
    }
  },
  components: {
    PinCode,
    MobileNavigation,
    // DarkMode
    MenuItem
  },
  mounted() {
    this.$i18n.locale = this.lang;
    this.pinCheck();
  },
  methods: {
    // toggleDark: function() {
    //   document.querySelector("html").classList.toggle("dark");
    //   document.querySelector("#app").classList.toggle("darkMode");
    // },
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../../assets/logo_dark.png");
      } else {
        return require("../../assets/logo_light.png");
      }
    },
    logOut() {
      this.logOutError = false;
      const pinCheckResult = store.dispatch('logOut');
      pinCheckResult.then(res => {
        if (res != 'success') {
          this.logOutError = true;
        } else {
          this.$router.push('/login');
        }
      }).catch((res) => {
        this.logOutError = true;
      });
    },
    pinCheck() {
      try{
        const result = this.$store.dispatch('pinCheck');
        result.then(res => {
          if (res && res != '') {
            this.pinExist = true;
          } else {
            this.pinExist = false;
          }
        }).catch(() => {
          this.pinExist = false;
        })
      }catch(e){
        this.pinExist = false;
      }
    },
  },
  computed: {
    src() {
      if (window.localStorage.theme == 'light') {
        return require("../../assets/Logo-dark.png");
      } else {
        return require("../../assets/Logo-light.png")
      }
    }
  }
}
</script>
